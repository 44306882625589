/**
 *  Gets the initials of Full Name
 * @param name
 * @returns first character of first word and last first character of last word
 */
export const getInitials = (name: string): string => {
  const allNames = name.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
};

/**
 *  Gets the a number formatted in currency format with commas
 * @param amount
 * @returns the number comma separated prefixed with currency symbol $
 */
export const formatCurrency = (amount: number, currency = 'USD'): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });
  return formatter.format(amount);
};
