import { ConfigProvider, Layout, theme } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import logo from '../../assets/images/logo.png';
interface Props {
  children?: React.ReactNode;
}

const AppLayout: React.FC<Props> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        components: {
          Button: {
            borderRadiusLG: 20,
          },
          Card: {
            borderRadiusLG: 20,
          },
          Avatar: {
            borderRadius: 15,
          },
          Input: {
            borderRadiusLG: 20,
          },
        },
      }}
    >
      <Layout className="h-screen text-white">
        <Header className="flex items-center bg-slate-900 py-4 px-6">
          <img src={logo} alt="logo" className="h-8 md:h-10" />
        </Header>
        <Content className="container mx-auto py-4 px-6">{children}</Content>
        <Footer style={{ textAlign: 'center' }}>Developed by Joseph S. William @ thndr</Footer>
      </Layout>
    </ConfigProvider>
  );
};
export default AppLayout;
