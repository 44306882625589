import { notification } from 'antd';
import { useQuery } from 'react-query';
import { TickersApi } from '../api';

export const useFetchTicker = (symbol?: string) => {
  const onError = () => notification.error({ message: 'Something Went Wrong!' });

  return useQuery(['ticker', symbol], () => (symbol ? TickersApi.fetch(symbol) : undefined), {
    staleTime: 60000,
    onError,
    refetchOnWindowFocus: false,
  });
};
