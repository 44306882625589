import { ITickerDetails } from '@polygon.io/client-js';
import { ITickersResults } from '@polygon.io/client-js/lib/rest/reference/tickers';
import { Avatar, Card, Tooltip } from 'antd';
import { truncate } from 'lodash';
import React from 'react';
import { useQueryClient } from 'react-query';
import { getInitials } from '../../utils';

type Props = {
  ticker: ITickersResults;
  loading?: boolean;
};

export const TickerCard: React.FC<Props> = ({ ticker, loading }) => {
  const queryClient = useQueryClient();
  const storeTicker: ITickerDetails | undefined = queryClient.getQueryData([
    'ticker',
    ticker.ticker,
  ]);
  const logoUrl = `${storeTicker?.results?.branding?.icon_url}?apiKey=${process.env.REACT_APP_API_KEY}`;

  return (
    <div className="w-full flex justify-center">
      <Card
        className="xl:w-64 xl:h-64 h-40 w-40"
        loading={loading}
        bodyStyle={{ padding: 0, height: '100%' }}
      >
        <div className="flex flex-col items-center gap-2 h-full text-center justify-center">
          {storeTicker?.results?.branding?.icon_url ? (
            <Avatar size={64} shape="square" src={logoUrl} />
          ) : (
            <Avatar size={64} shape="square" children={getInitials(ticker.name)} />
          )}
          <span>{ticker.ticker}</span>
          <Tooltip title={ticker.name}>
            <span className="text-xs text-gray-400">{truncate(ticker.name, { length: 20 })}</span>
          </Tooltip>
        </div>
      </Card>
    </div>
  );
};
