import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { useBoolean } from 'react-use';
import TickerDetails from '../../components/TickerDetails';
import { useFetchTicker, useFetchTickerStats } from '../../hooks';

type Props = {
  symbol?: string;
  onClose?: () => void;
};

const TickerDetailsModal: React.FC<Props> = ({ symbol, onClose }) => {
  const [open, toggle] = useBoolean(false);
  const tickerMutation = useFetchTicker(symbol);
  const statsMutation = useFetchTickerStats(symbol);
  const loading = tickerMutation.isLoading || statsMutation.isLoading;

  const handleOpen = () => toggle(true);

  const handleClose = () => {
    toggle(false);
    onClose?.();
  };

  useEffect(() => {
    symbol && handleOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol]);

  return (
    <Modal className="top-10" destroyOnClose open={open} onCancel={handleClose} footer={false}>
      <TickerDetails ticker={tickerMutation.data} stats={statsMutation.data} loading={loading} />
    </Modal>
  );
};

export default TickerDetailsModal;
