import { ITickersQuery } from '@polygon.io/client-js';
import { notification } from 'antd';
import { useInfiniteQuery } from 'react-query';
import { TickersApi } from '../api';

export const useListTickers = (params?: ITickersQuery) => {
  const onError = () => notification.error({ message: 'Something Went Wrong!' });

  return useInfiniteQuery(
    ['tickers', params],
    ({ pageParam }) => TickersApi.list({ ...params, cursor: pageParam }),
    {
      refetchOnWindowFocus: false,
      onError,
      staleTime: 60000,
      getNextPageParam: (lastPage) => lastPage?.next_url?.split('?cursor=')?.[1],
    }
  );
};
