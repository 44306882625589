import { Input, List, Space, Spin } from 'antd';
import _, { debounce } from 'lodash';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TickerCard } from '../../components';
import { useListTickers } from '../../hooks/useListTickers';
import TickerDetailsModal from './TickerDetailsModal';

const Home = () => {
  const [search, setSearch] = useState<string>('');
  const [selectedTicker, setSelectedTicker] = useState<string | undefined>();

  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } = useListTickers({
    search,
  });
  const dataSource = _(data?.pages)
    .map((page) => page?.results)
    .flatten()
    .value();
  const debounceSearch = debounce(setSearch, 1000);
  const loading = isFetching || isFetchingNextPage;

  const clearSelected = () => setSelectedTicker(undefined);

  return (
    <Space direction="vertical" className="w-full" size="large">
      <Input
        allowClear
        size="large"
        placeholder="Search for stocks"
        onChange={(e) => debounceSearch(e.target.value)}
        className="w-full"
      />

      <div id="scrollableDiv" style={{ height: 'calc(100vh - 14rem', overflowX: 'hidden' }}>
        <InfiniteScroll
          style={{ overflowX: 'hidden' }}
          dataLength={dataSource.length}
          next={() => fetchNextPage()}
          hasMore={hasNextPage === true}
          loader={<Spin spinning={isFetchingNextPage} className="w-full" tip="Loading.." />}
          scrollableTarget="scrollableDiv"
        >
          <List
            loading={loading}
            grid={{
              gutter: 14,
              xs: 2,
              sm: 3,
              md: 4,
              lg: 4,
              xl: 4,
              xxl: 5,
            }}
            dataSource={dataSource}
            renderItem={(item) => (
              <List.Item onClick={() => setSelectedTicker(item.ticker)}>
                {item && <TickerCard ticker={item} />}
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
      <TickerDetailsModal symbol={selectedTicker} onClose={clearSelected} />
    </Space>
  );
};

export default Home;
