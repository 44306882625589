import { IAggsPreviousClose, ITickerDetails } from '@polygon.io/client-js';
import { Avatar, Button, Divider, Skeleton } from 'antd';
import { find, isEmpty, isUndefined } from 'lodash';
import { formatCurrency, getInitials } from '../../utils';

type Props = {
  ticker?: ITickerDetails;
  stats?: IAggsPreviousClose;
  loading?: boolean;
};

const TickerDetails: React.FC<Props> = ({ ticker, stats, loading }) => {
  if (loading) return <Skeleton avatar paragraph={{ rows: 10 }} active />;

  const logoUrl = `${ticker?.results?.branding?.icon_url}?apiKey=${process.env.REACT_APP_API_KEY}`;
  const initials = ticker?.results?.name ? getInitials(ticker?.results?.name) : 'N/A';
  const statistics = find(stats?.results, (r) => r.T === ticker?.results?.ticker);
  const changePercent =
    statistics?.c && statistics?.o && ((statistics.c - statistics.o) / statistics.o) * 100;
  const changePercentColor = (() => {
    if (!changePercent) return 'text-gray-400';
    if (changePercent > 0) return 'text-green-500';
    if (changePercent < 0) return 'text-red-500';
    return '';
  })();
  const changePercentStr =
    changePercent && changePercent > 0
      ? `+${changePercent?.toFixed(2)}%`
      : `${changePercent?.toFixed(2)}%`;

  return (
    <div className="flex flex-col gap-12">
      <div>
        <div className="flex justify-between">
          <div className="flex items-center gap-3">
            {ticker?.results?.branding?.icon_url ? (
              <Avatar size={50} shape="square" src={logoUrl} />
            ) : (
              <Avatar size={50} shape="square" children={initials} />
            )}
            <div className="flex flex-col">
              <span className="text-2xl font-bold">{ticker?.results?.ticker}</span>
              <div className="flex gap-2 text-sm font-semibold">
                {statistics?.c && (
                  <span>{formatCurrency(statistics.c, ticker?.results?.currency_name)}</span>
                )}
                {!isUndefined(changePercent) && (
                  <span className={changePercentColor}>{changePercentStr}</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <Divider type="horizontal" />

        {!!ticker?.results?.description && (
          <>
            <div className="flex flex-col gap-2">
              <span className="font-bold">ABOUT</span>
              <span className="text-gray-400 max-h-24 md:max-h-40 overflow-y-auto">
                {ticker?.results?.description}
              </span>
            </div>

            <Divider type="horizontal" />
          </>
        )}

        {!!statistics && (
          <>
            <div className="flex flex-col gap-2">
              <span className="font-bold">STATISTICS</span>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className="flex flex-col gap-1">
                  <span className="text-gray-400">Open</span>
                  <span>
                    {statistics?.o && formatCurrency(statistics.o, ticker?.results?.currency_name)}
                  </span>
                </div>
                <div className="flex flex-col gap-1">
                  <span className="text-gray-400">Close</span>
                  <span>
                    {statistics?.c && formatCurrency(statistics.c, ticker?.results?.currency_name)}
                  </span>
                </div>
                <div className="flex flex-col gap-1">
                  <span className="text-gray-400">High</span>
                  <span>
                    {statistics?.h && formatCurrency(statistics.h, ticker?.results?.currency_name)}
                  </span>
                </div>
                <div className="flex flex-col gap-1">
                  <span className="text-gray-400">Low</span>
                  <span>
                    {statistics?.l && formatCurrency(statistics.l, ticker?.results?.currency_name)}
                  </span>
                </div>
              </div>
            </div>
            <Divider type="horizontal" />
          </>
        )}
      </div>

      <Button
        href={ticker?.results?.homepage_url}
        target="_blank"
        className="w-full"
        size="large"
        disabled={isEmpty(ticker?.results?.homepage_url)}
      >
        Visit Website
      </Button>
    </div>
  );
};

export default TickerDetails;
