import {
  IAggsPreviousClose,
  ITickerDetails,
  ITickers,
  ITickersQuery,
  restClient,
} from '@polygon.io/client-js';

const rest = restClient(process.env.REACT_APP_API_KEY);

export namespace TickersApi {
  export const list = async (query?: ITickersQuery): Promise<ITickers> => {
    const response = await rest.reference.tickers(query);

    return response;
  };

  export const fetch = async (symbol: string): Promise<ITickerDetails> => {
    const response = await rest.reference.tickerDetails(symbol);

    return response;
  };

  export const stats = async (symbol: string): Promise<IAggsPreviousClose> => {
    const response = await rest.forex.previousClose(symbol);

    return response;
  };
}
